import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './modules/routes/app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './modules/component/intro/intro.component';
import { ClientsComponent } from './modules/component/clients/clients.component';
import { ContactComponent } from './modules/component/contact/contact.component';
import { TechStackComponent } from './modules/component/tech-stack/tech-stack.component';
import { WorkHistoryComponent } from './modules/component/work-history/work-history.component';
import { EducationalComponent } from './modules/component/educational/educational.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    ClientsComponent,
    ContactComponent,
    TechStackComponent,
    WorkHistoryComponent,
    EducationalComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
